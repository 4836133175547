@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-100 text-[#121212]
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  @apply bg-white rounded-xl shadow-xl;
}

.btn-primary{
  @apply bg-blue-500 hover:bg-blue-600 rounded-xl text-white font-bold px-8 py-4;
}

.link-primary{
  @apply text-blue-500 hover:text-blue-600 font-bold;
}

.form-control{
  @apply text-center py-2 px-4 bg-[#e6e7e9] border-0 rounded-xl uppercase text-3xl block w-full focus:outline-blue-500;
}
.form-label{
  @apply font-bold block mb-3;
}
.form-group {
  @apply flex flex-col items-center mb-4;
}